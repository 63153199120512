import React from "react";
import { AboutSoftTouch1stCard } from "./AboutSoftTouch1stCard";
import { AboutSoftTouch2ndCard } from "./AboutSoftTouch2ndCard";

export const AboutSoftTouch = () => {
  console.log("window size", window?.innerHeight);
  console.log("window size", window?.innerWidth);

  return (
    <div className="px-0 xl:px-44">
      <AboutSoftTouch1stCard />
      <AboutSoftTouch2ndCard />
    </div>
  );
};
