import React, { useEffect, useState } from 'react';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const questions = [
        {
            question: 'What is the purpose of this dating app?',
            answer: 'Our dating app is designed to help you connect with like-minded individuals and potentially find meaningful relationships.'
        },
        {
            question: 'How do I create an account?',
            answer: 'To create an account, simply download the app from the App Store or Google Play, open it, and follow the on-screen instructions to sign up.'
        },
        {
            question: 'Is there a cost to use this dating app?',
            answer: 'The basic features of our dating app are free to use. However, we also offer premium subscription plans with additional features and benefits.'
        },
        {
            question: 'How can I delete my account?',
            answer: 'To delete your account, go to the settings menu within the app, find the account settings section, and choose the option to delete your account. Please note that this action is irreversible.'
        },
        {
            question: 'Can I upgrade my account?',
            answer: 'Yes, you can upgrade your account to access premium features such as unlimited messaging, advanced search filters, and more. Visit the app’s subscription section to upgrade.'
        },
        {
            question: 'Do you have a mobile app?',
            answer: 'Yes, our dating app is available for both iOS and Android devices. You can download it from the respective app stores.'
        },
        {
            question: 'How do I contact customer support?',
            answer: 'If you have any questions or need assistance, you can contact our customer support team directly through the app. Look for the “Contact Us” or “Support” option in the settings menu.'
        },
        {
            question: 'What privacy settings are available?',
            answer: 'We prioritize user privacy and offer robust privacy settings. You can control who can see your profile, manage your visibility settings, and block or report users if needed.'
        }
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="text-center mt-6 overflow-x-hidden">
            <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
                className="text-[#c51369] text-lg tracking-wider font-semibold mb-4">
                <span className="border-b-2 border-[#c51369] pb-1">
                    Faq's
                </span>
            </div>

            <div className="text-[#0d0b0b] mt-6 text-2xl md:text-4xl tracking-wider font-bold">
                Some Common Queries
            </div>

            <div className="flex flex-col md:flex-row py-6 px-4 md:px-16">
                {/* First Column */}
                <div
                    data-aos="zoom-in-left"
                    className="w-full md:w-1/2 p-4">
                    {questions.slice(0, 4).map((item, index) => (
                        <div key={index} className="mb-4">
                            <button
                                className={`flex tracking-wider justify-between items-center text-[#0d0b0b] w-full ${activeIndex === index ? 'bg-[#c51369] text-white' : 'bg-gray-100'} border border-[#c51369] rounded-lg px-4 py-2 focus:outline-none`}
                                onClick={() => toggleAccordion(index)}
                            >
                                <span className="text-lg font-semibold">{item.question}</span>
                                {activeIndex === index ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}
                            </button>
                            <div
                                className={`mt-2 text-start tracking-wider text-gray-600 overflow-hidden transition-all duration-500 ease-in-out max-h-0 ${activeIndex === index ? 'max-h-[100px]' : 'max-h-0'
                                    }`}
                            >
                                <p>{item.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Second Column */}
                <div
                    data-aos="zoom-in-right"
                    className="w-full md:w-1/2 p-4">
                    {questions.slice(4, 8).map((item, index) => (
                        <div key={index + 4} className="mb-4">
                            <button
                                className={`flex tracking-wider justify-between items-center w-full ${activeIndex === index + 4 ? 'bg-[#c51369] text-white' : 'bg-gray-100'} border border-[#c51369] rounded-lg px-4 py-2 focus:outline-none`}
                                onClick={() => toggleAccordion(index + 4)}
                            >
                                <span className="text-lg font-semibold">{item.question}</span>
                                {activeIndex === index + 4 ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}
                            </button>
                            <div
                                className={`mt-2 text-start tracking-wider text-gray-600 overflow-hidden transition-all duration-500 ease-in-out max-h-0 ${activeIndex === index + 4 ? 'max-h-[100px]' : 'max-h-0'
                                    }`}
                            >
                                <p>{item.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Faq;
