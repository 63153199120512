import React from "react";
import { GetInTouch } from "../../Components/ContactUs/GetInTouch";
import { SecondaryNav } from "../../Components/Common/SecondaryNav";
import contactUsImage from "../../Assets/contact-us.jpg";

const Contact = () => {
  return (
    <React.Fragment>
      <SecondaryNav
        banner={contactUsImage}
        page_name="Contact Us"
        desc="Discover meaningful connections with our innovative dating app. Join now and start your journey to meaningful relationships!"
      />
      <GetInTouch />
    </React.Fragment>
  );
};

export default Contact;
