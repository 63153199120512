import React from "react";
import { OurTeamCards } from "./OurTeamCards";

export const OurTeam = () => {
  return (
    <React.Fragment>
      <div className="flex flex-col justify-center items-center pt-12 xl:pt-32">
        <h4 className="text-[#CE3780] text-lg font-semibold mb-2">Our Team</h4>
        <div className="border-b border-[#CE3780] w-9 mb-4"></div>
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
          Meet Our Professional Team
        </h2>
        <p className="text-gray-700 leading-relaxed text-center mb-4 w-full px-2 md:w-[50%]">
          We are proud to introduce the dedicated professionals behind Heart
          Connect. Our team combines expertise, passion, and commitment to make
          your experience exceptional.
        </p>
      </div>

      {/* our team cards */}
      <OurTeamCards />
    </React.Fragment>
  );
};
