import React from 'react';
import banner from '../../Assets/bbanner.jpg';
import mob from '../../Assets/mobImg.png';
import appStoreImg from '../../Assets/playstore.png';
import gif from '../../Assets/heart-12791_256.gif';

const DownloadedApp = () => {
    return (
        <div
            className="flex flex-col md:flex-row px-4 md:px-16"
            style={{ backgroundImage: `url(${banner})` }}
        >
            {/* First Column */}
            <div className="flex flex-col justify-center items-center w-full md:w-1/2 p-8">
                <div>
                    <div data-aos="fade-right" className="md:mt-16 mt-2 text-[#c51369] text-lg tracking-wider font-semibold mb-4 text-start">
                        <span className="border-b-2 border-[#c51369] pb-1">
                            Download App
                        </span>
                    </div>

                    <div data-aos="fade-right" className="text-[#0d0b0b] text-start mt-6 text-2xl md:text-4xl tracking-wider font-bold">
                        Over 30 Million Downloads
                    </div>

                    <div data-aos="flip-up" className='text-lg tracking-wide text-[#0d0b0b] mt-6 text-justify'>
                        Our app has achieved over 30 million downloads, highlighting its global popularity and trust. This milestone reflects the quality and reliability that users experience daily. Join millions who have enhanced their lives with our app and discover its exceptional value.
                    </div>

                    <div className='mt-6'>
                        <img
                            data-aos="flip-left"
                            src={appStoreImg}
                            alt="img"
                            className="w-auto h-auto" />
                    </div>

                    <div className="flex justify-center w-full mt-2">
                        <img src={gif} alt="gif" />
                    </div>
                </div>
            </div>

            {/* Second Column */}
            <div className="flex justify-center items-center w-full md:w-1/2 px-6 py-16">
                <img
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    src={mob}
                    alt="img"
                    className="w-[90%] h-auto object-cover" />
            </div>
        </div>
    )
}

export default DownloadedApp
